<template>
    <div>
      <page-breadcrumb title="Report" class="mb-2" />
      <div class="d-flex align-items-center">
        <form-generator :model="filter" :schema="filter_schema" />
      </div>
      <data-table-ssr id="group_list" ref="group_list" :columns="fields" :get-list-fn="getReport" />
    </div>
  </template>
  <script>
  const fields = [
    { label: 'Teacher email', field: 'teacher_email' },
    { label: 'All Slots', field: 'available'},
    { label: 'Booked (Total)', field: 'booked' },
    { label: 'Canceled by Student (Total)', field: 'cancel_student' },
    { label: 'Canceled by Teacher (Total)', field: 'cancel_teacher' },
    { label: 'Student - No show', field: 'student_no_show' },
    { label: 'Teacher - No show', field: 'teacher_no_show' },
    { label: 'Finished (early)', field: 'finished_early' },
    { label: 'Finished (late)', field: 'finished_late' },
  ];
  const teacher_email_options = []
  const filter_schema = [
    {
      cols: 4, fields: [{ field: 'start_date', label: 'Start Date', input_type: 'date' }]
    },
    {
      cols: 4, fields: [{ field: 'end_date', label: 'End Date', input_type: 'date' }]
    },
    {
      cols: 3, fields: [{ field: 'teacher_email_key', label: 'Teacher' }]
    },
  ]
  import service from '../service'
  export default {
    data() {
      return {
        fields,
        filter_schema,
        filter: {
          start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end_date: new Date()
        }
      }
    },
    watch:{
      filter: {
        handler(){
          if (this.filter.teacher_email_key){
            this.filter.teacher_email_key = this.filter.teacher_email_key.trim();
            this.filter.teacher_email = {$regex: this.filter.teacher_email_key, "$options": 'i'}
          } else{
            delete this.filter.teacher_email
          }
          this.$refs.group_list.getList();
        },
        deep: true
      }
    },
    methods: {
      async getReport({ limit, page, query }) {
        let response_data = await service.getTeacherReport({
          filter: JSON.stringify(this.filter),
          limit,
          page
        });
        let list = [], total = 0;
        if (response_data) {
          list = response_data.list;
          total = response_data.total;
          this.total = total;
        }
        return { list, total };
      },
      async getTeacherList(){
        let response_data = await service.getTeacherList();
        if (response_data){
          let options = response_data.list.map((item) => {return {text: item.email, value: item._id}});
          teacher_email_options.push(...options);
        }
      }
    }
  }
  </script>